import Vue from 'vue'
import App from './App.vue'
import store from './store/index'

//router
import VueRouter from 'vue-router'

//Meta
import Meta from 'vue-meta';

import axios from 'axios'
import VueAxios from 'vue-axios'

//icons 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faLinkedin, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueLazyload from 'vue-lazyload'

import "./filters"

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

library.add(faInstagram, faLinkedin, faFacebookSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(VueRouter);
Vue.use(Meta);
Vue.use(VueAxios, axios)

Vue.use(VueLazyload)

import AOS from "aos";
import "aos/dist/aos.css";

Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: lazyLoad('Home')},
    { path: '/about', component: lazyLoad('About')},
    { path: '/contact', component: lazyLoad('Contact')},
    { path: '/properties', component: lazyLoad('Properties')},
    { path: '/property/:slug', component: lazyLoad('Property')},
    { path: "*", component: lazyLoad('PageNotFound') },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted(){
    AOS.init()
  },
}).$mount('#app')
