<template>
    <div id="footerCredits">
        <p v-html="copyright"></p>
        <br>
        <p style="display:inline">
            {{credits}}
        </p>
        <a href="https://www.austinrobbins.com" target="_blank">Austin Robbins Agency</a>
    </div>
</template>

<script>
export default {
    name: "FooterCredits",
    data(){
        var d = new Date();
        return{
            copyright: "Copyright &copy " + d.getFullYear() + " Hannah DelPonte. DRE# 01479408. Hannah DelPonte is a real estate salesperson licensed by the state of California affiliated with Compass. Compass is a real estate broker licensed by the state of California and abides by equal housing opportunity laws. All material presented herein is intended for informational purposes only. Information is compiled from sources deemed reliable but is subject to errors, omissions, changes in price, condition, sale, or withdrawal without notice. No statement is made as to accuracy of any description. All measurements and square footages are approximate. This is not intended to solicit property already listed. Nothing herein shall be construed as legal, accounting or other professional advice outside the realm of real estate brokerage.",
            credits: "Luxury Real Estate Websites by",
        }
    },
}
</script>

<style lang="scss" scoped>
#footerCredits{
    padding: 1.5rem 0;
    padding-bottom: 3.5rem;
    text-align: center;
    p {
        margin: 0 auto;
        padding: 0;
        letter-spacing: 1px;
        font-size: 0.75rem;
        max-width: 80%;
    }
    a{
        font-size: 0.75rem;
        padding: 0;
        color: #fff;
        text-decoration: none;
        font-weight: 700;
    }
    a:hover{
        opacity: 0.7;
    }
}
</style>