<template>
  <div id="app" role="main">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer.vue'

export default {
  name: 'app',
  components: {
    Header,
    Footer
  },
  mounted: function () {
    if (!this.$store.getters.loaded || this.$store.getters.checkExpiryDate) {
      this.$store.dispatch("getProperties");
    }
  },
}

import 'vueperslides/dist/vueperslides.css';
</script>

<style lang="scss">
@import url("https://use.typekit.net/uih2dzc.css");
html, body{
  font-family: $primaryFont;
  margin:0;
  padding:0;
  min-height: 100vh;
  color: #000;
}

a{
  color: #000;
  &:hover{
    opacity: 0.85;
  }
}

*{
  box-sizing: border-box;
}

p{
  font-size: 1rem;
  margin: 0.25rem 0;
  line-height: 1.75rem;

}

button:focus{
  outline: none;
}

.vue-simple-spinner-text{
  letter-spacing: 3px;
  font-size: 1rem !important;
  color: #000 !important;
}
</style>