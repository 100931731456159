import Vue from "vue"

Vue.filter(
    "priceFormat", function (price) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0
        })
        return formatter.format(price);
    }
)

Vue.filter(
    "startCase", function (value) {
        return Vue.lodash.startCase(value)
    }
)