<template>
    <nav role="navigation" :class="{ nonTransparentbg: transparentBackground }">
        <div id="menuToggle">
            <input 
              aria-label="Open menu"
              type="checkbox"
              v-model="menuShow"
              />
            <img class="btn_menu" alt="Menu button" :class="{ 'btn_close': menuShow }" :src="getOpenCloseIcon()"/>
        </div>
        <router-link to="/" class="property_tile">
          <h1 class="desktop">Hannah Delponte Vollera</h1>
        </router-link>
        <transition name="fade">
          <div id="menu" v-if="menuShow"> 
            <ul>
              <li v-for="item in menuItems" :key="item.label">
                <router-link :to="item.link" @click.native="linkClick">
                  {{ item.label }}
                </router-link>
              </li>
            </ul>
            <hr />
            <p><a class="contact" :href="'tel:'+ telephone">{{telephone}}</a></p>
            <p><a class="contact" :href="'mailto:' + email ">{{email}}</a></p>
            <!-- <FooterSocialMedia></FooterSocialMedia> -->
          </div>
        </transition>
    </nav>
</template>
<script>

// import FooterSocialMedia from "../Footer/FooterSocialMedia"

export default {
    name: 'Menu',
    // components: {
    //   FooterSocialMedia
    // },
    data(){
        return{ 
          menuItems: [
              {
                  label: "Home",
                  link: "/"
              },
              {
                  label: "About",
                  link: "/about"
              },
              {
                  label: "Properties",
                  link: "/properties"
              },
              {
                  label: "Contact",
                  link: "/contact"
              },
          ],
          telephone: "(310) 902-1090",
          email: "hannah@thehannahgroup",
          menuShow: false,
          transparentBackground: false,
      }
    },
    computed:{
      navLogo() {
        if(this.transparentBackground){
          return '/images/nav_logo.png'
        }else{
          return '/images/nav_logo-w.svg'
        }
      },
    },
    methods: {
      linkClick(){
        this.menuShow = false;
      },
      handleScroll(){
        this.transparentBackground = window.scrollY > 100;
      },
      getOpenCloseIcon(){
          if(this.menuShow){
            return '/images/hamburger-close.png'
          }
          else if (this.transparentBackground){
            return '/images/menu.png';
          }
          else {
            return '/images/menu-white.png'
          }
      }
    },
    created: function(){
      if (this.$router.currentRoute.path === "/"){
        this.transparentBackground = false;
        window.addEventListener('scroll', this.handleScroll);
      }else{
        this.transparentBackground = true;
      } 
    },
    destroyed(){
      if (this.$router.currentRoute.path === "/"){
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
    watch:{
      $route (to){
        this.menuShow = false;
          if(to.path !== '/'){
            window.removeEventListener('scroll', this.handleScroll);
            this.transparentBackground = true;
          }else{
            window.addEventListener('scroll', this.handleScroll);
            this.transparentBackground = false;
          }
          
      }
    } 
    
}
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  position: sticky;
  top: 0;
  height: 59px;
  display: flex;
  justify-content: center;
  z-index: 20;
  border-bottom: 1px solid #fff;
  h1{
    color: #fff;
    margin:20px 6px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 16px;
  }
  a{
    &:visited{
      color: #fff;
    }
    color: #fff;
    text-decoration: none;
  }
  hr{
    width: 40%;
  }
  p{
    font-weight: bold;
    margin: 1rem 0;
  }
}

.btn_menu{
  margin-top: 11px;
  width: 26px;
  height: 13px;
  &.btn_close{
      width:18px;
      height: 18px;
      margin-right: 6px;
  }
}

.nonTransparentbg{
  background: white;
  border-bottom: 1px solid #bfbfbf;
  .logo_title{
    letter-spacing: 1.2px;
    a{
      color: #000;
    }
    color: #000;
  }
  #menuToggle{
    span{
      background: #000;
    }
  }
  h1{
    color: #000;
  }
}


#menuToggle {
  position: absolute;
  top: 13px;
  right: 45px;
  z-index: 20;
  user-select: none;
  & input {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    left: -3px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
  }
}

#menu
{
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  display: block;
  background-color:rgba(0,0,0, 0.9);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul{
    list-style-type: none;
    margin: 0 auto;
    margin-top: 40px;
    padding-inline-start: unset;
  }
  ul>li {
    color:#fff;
    font-size: 55px;
    line-height: 60px;
    margin: 20px 0;
    &:hover{
      opacity: 0.85;
    }
  }
  .contact{
    font-size: 24px;
  }
}

@media (max-width: $mobile) { 
  nav {
    justify-content: flex-start;
    h1{
      font-size: 14px;
    }
    .logo{
      margin-left: 10px;
    }
    .property_tile{
      margin-left:1.5rem;
    }
  }
  #menu{
    ul>li {
      font-size: 35px;
    }
  }
}
</style>