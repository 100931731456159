<template>
    <div class="footer-content">
        <router-link to="/" class="property-tile">  
            <img class="logo" src="/images/client-logo.svg" alt="Client Logo">
        </router-link>
        <div class="phone-numbers">
            <p><a class="contact" :href="'tel:'+ cell">{{cell}}</a> <span class="phone_type">CELL</span></p>
            <!-- <p class="second"><a class="contact" :href="'tel:'+ telephone">{{telephone}} x.917</a> <span class="phone_type">OFFICE</span></p> -->
        </div>
        <p><a class="contact" :href="'mailto:' + email ">{{email}}</a></p>
        <hr>
    </div>
</template>

<script>
export default {
    name: "FooterContent",
    data(){
        return {
            title: "Hannah DelPonte Vollera",
            cell: '310-902-1090',
            telephone: "888.959.9461",
            email: "HANNAH@THEHANNAHGROUP.COM",
        }
    }
}
</script>

<style lang="scss" scoped>
.footer-content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    .logo{
        width: 100%;
        min-width: 300px;
        max-width: 350px;
        margin: 2rem 0;
    }
    * {
        font-weight: 600;
    }
    hr{
        margin: 2rem auto;
        width: 90%;
    }
    .contact{
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 3px;
    }
    .property-tile{
        display:block;
    }
    a{
        color:#fff;
    }
}
.phone-numbers{
    display: flex;
    align-items: center;
    justify-content: center;
    .first{
        border-right: 1px solid #000;
        padding-right: 1rem;
    }
    .second{
        padding-left: 1rem;
    }
    .phone_type{
        letter-spacing: 3px;
        color: #a0acb2;
    }
}
@media (max-width: $mobile) {
    .footer-content{
        a{
            padding: 1rem 0;
        }
        .logo{
            width: 100%;
            max-width: 250px;
        }
        .contact{
            font-size: 12px;
        }
    }
    .phone-numbers{
        .phone_type{
            font-size: 12px;
        }
    }
}
</style>